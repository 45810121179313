import {useState,useRef,useEffect,useContext,createContext} from "react";
import {useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const authenticateApi = "api/authenticate"
const authContext = createContext();

const AuthProvider = ({children}) => {
    const auth = useProviderAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
}

function useProviderAuth(){
    const [user,setUser] = useState(null);
    const [loginError,setLoginError] = useState(null);
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    let navigateTo= useRef("");
    const sessionTimeout = useRef(1);
    let timeOut;
    const signIn = async (userName,password,nav) =>{
        try{
            //debugger;
            setLoading(true);
            const body1 = {UserName:userName,Password:password};
            // const response = await fetch(`${baseApiUrl}${authenticateApi}?UserName=${userName}&Password=${password}`);
            //const response = await fetch(`${baseApiUrl}${authenticateApi}/${userName}/${password}`);
            const response = await fetch(`${baseApiUrl}${authenticateApi}`
            ,{
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors',
                
                headers:{
                    'Content-Type': 'application/json', 
                    'Accept' : 'application/json'
                    
                }
                //,data:a
                 ,body: JSON.stringify(body1)
                 
            }
        );
            if (response.ok){
                const result = await response.json();
                const decodedToken = jwt_decode(result.token);
                sessionTimeout.current = parseInt(decodedToken.exp)*1000-Date.now();

                navigateTo.current = nav;
                setUser( oldUser => {return {...oldUser,token : result.token
                                                        ,loginId: decodedToken.loginId
                                                        ,AdvisorId: decodedToken.AdvisorId
                                                        ,firstName : decodedToken.firstName,lastName : decodedToken.lastName
                                                        ,flag_can_create_referral : decodedToken.flag_can_create_referral
                                                        ,flag_can_approve_referral : decodedToken.flag_can_approve_referral
                                                        ,flag_can_view_referral_history : decodedToken.flag_can_view_referral_history
                                                        ,flag_can_view_reports : decodedToken.flag_can_view_reports
                                                        ,flag_can_create_salesperson : decodedToken.flag_can_create_salesperson
                                                    }});
                
                timeOut = setTimeout(() => {
                    setUser(null);                    
                }, sessionTimeout.current);

            }
            else {
                console.log(response.status); 
                const e = response.statusText;
                setUser(null);
                setLoginError(e);
                setLoading(false);
              }
        }
        catch(e){
            console.log(e);
            setUser(null);
            setLoginError(e);
            setLoading(false);
        }
        
    }

    useEffect(() => {
        //debugger;
        if (user && navigateTo.current){            
            setLoading(false);
            navigateTo.current="";
            navigate("/home");
        }
        if (!user && timeOut) {    console.log("Clearing Timeout");    clearTimeout(timeOut);}
    }, [user])

    const signOut = () =>{
        setUser(null);
    }

    return {
        user
        ,signIn
        ,loginError
        ,signOut
        ,loading
    }
}

export default AuthProvider;