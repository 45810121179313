import {useState,useEffect,useRef} from "react";
import {useParams} from "react-router-dom";
import {Input,NumericTextBox} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns"
import {Button} from "@progress/kendo-react-buttons";
import {useAuth} from "./customHooks/authContext";
import {getUserDealers} from "./services/dealerService";
import {getDealerSalesPersons} from "./services/salesPersonService";

import "./styles/referrals.css";
import {createReferral,getReferralsById,validateReferral} from "./services/referralsService";
// const dealersList = [
//     {"dealer_Id":1, "dealer_Name":"Penfold Mazda"}
//     ,{"dealer_Id":2, "dealer_Name":"Penfold Toyota"}
//     ,{"dealer_Id":3, "dealer_Name":"Penfold Honda"}
// ];
const Referrals = () =>{
    const auth = useAuth();
     const {id} = useParams();
    // const get = async () => {
    //     return await getUserDealers(2636411);//auth.user.AdvisorId
    // }
    // const [dealers,SetDealers] = useState(() => { return get();}
    // //{
    //     //return dealersList;
    //     //return getUserDealers(auth.user.AdvisorId);
    // //}
    // );
    const [dealers,setDealers] = useState([{dealer_Id:-1,dealer_Name:'Select'}]);
    const [selectedDealer,setSelectedDealer] = useState({dealer_Id:-1,dealer_Name:'Select'});

    const[salesPersons,setSalesPersons] = useState([{advisor_Id:-1,name:'Select'}]  );
    const [selectedSalesPerson,setSelectedSalesPerson] = useState({advisor_Id:-1,name:'Select'});

    const[giftCard,setGiftCard] = useState();
    const[purchaseOrder,setPurchaseOrder] = useState("");
    const[membershipNo,setMembershipNo] = useState("");
    const[membershipName,setMembershipName] = useState("");
    const[email,setEmail] = useState("");
    const [mobileNo,setMobileNo] = useState("");
    const[friendName,setFriendName] = useState("");
    const[stockNo,setStockNo] = useState("");

    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);
    const [touched,setTouched] = useState({});
    const [showMsg,setShowMsg] = useState(false);
    const referrals = useRef({});
    const message = useRef("");

    // const baseUrl = process.env.REACT_APP_BASE_API_URL;
    // const  getUserDealers = async (Advisor_Id) => {
    //     //debugger;
    //     try{

    //         const response = await fetch(baseUrl + `api/dealership/${Advisor_Id}`);
    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log(data);
    //             //let result = [];
    //             let result = Object.entries(data)

    //             // for (let i in data){
    //             //     result.push(data[i]);
    //             // }
    //             //console.log(result);//JSON.stringify()
                
                
    //             return data;
    //         }
    //     }
    //     catch(ex)
    //     {
    //         console.log(ex);
    //     }
    //     finally{
    //         setLoading(false);
    //     }
    //     //throw response;
    // }
    const getAllData = async () =>{
        try {
            const dealers = await getUserDealers(auth.user.AdvisorId,auth.user.token,auth.user.loginId);
            dealers.unshift({dealer_Id:-1,dealer_Name:'Select'});
            setDealers(dealers);
            

            if (parseInt(id) > 0)
            {
                referrals.current = await getReferralsById(id,auth.user.token,auth.user.loginId);    
                setGiftCard(referrals.current[0].giftcard_Value);
                setPurchaseOrder(referrals.current[0].purchase_Order_Num);
                setSelectedDealer({dealer_Id:referrals.current[0].dealer_Id,dealer_Name:referrals.current[0].dealer_Name});
                setMembershipName(referrals.current[0].cust_Name);
                setMembershipNo(referrals.current[0].membership_Num);
                setEmail(referrals.current[0].cust_Email);
                setMobileNo(referrals.current[0].cust_Mobile_No);
                setFriendName(referrals.current[0].referral_Name);
                setStockNo(referrals.current[0].stock_No);
            }
            else
            {
                if (dealers.length === 1){
                    setSelectedDealer( dealers[0] );                    
                }
                else{
                    setSelectedDealer(  {dealer_Id:-1,dealer_Name:'Select'});
                }
            }
            setLoading(false);
        } catch (error) {
            setError(error);
        }
        // finally{
        //     setLoading(false);
        // }
        
    }
    useEffect (()=>{        
        console.log("Use Effect Did Mount");
        
        getAllData();
        
        
        // setDealers( prev => {
        //     const d = getUserDealers(2636411);
        //     return {...prev,d }} );
    }
    ,[]
    )
    
    useEffect ( () => {
        //console.log("Use Effect Selected Dealer Change");
        const getSalesPersons = async () => {
            if (selectedDealer && selectedDealer.dealer_Id > 0){
                debugger;
                const salesPersons = await getDealerSalesPersons(selectedDealer.dealer_Id,auth.user.loginId);
                salesPersons.unshift({advisor_Id:-1,name:'Select'});
                setSalesPersons( salesPersons );
                if (parseInt(id) > 0)
                {
                    setSelectedSalesPerson({advisor_Id:referrals.current[0].salesperson_Id,name:referrals.current[0].salesperson_Name});
                }
                else
                {

                    if (salesPersons.length === 1){
                        setSelectedSalesPerson(salesPersons[0]);                    
                    }
                    else
                    setSelectedSalesPerson({advisor_Id:-1,name:'Select'});
                }
            }
            else
            {
                setSalesPersons([{advisor_Id:-1,name:'Select'}]);
                setSelectedSalesPerson({advisor_Id:-1,name:'Select'});
            }
        }

        getSalesPersons();
    },[selectedDealer]
    )
   
    const clearFields = () =>{
        setSalesPersons([{advisor_Id:-1,name:'Select'}]  );
    setSelectedSalesPerson({advisor_Id:-1,name:'Select'});

    setGiftCard();
    setPurchaseOrder("");
    setMembershipNo("");
    setMembershipName("");
    setEmail("");
    setMobileNo("");
    setFriendName("");
    setStockNo("");
    }
    useEffect( ()=>{
        // setDealers([{dealer_Id:-1,dealer_Name:'Select'}]);
        // setSelectedDealer({dealer_Id:-1,dealer_Name:'Select'});
        getAllData();
        clearFields();
    },[id])
    const handleDealerChange = (e) =>{       
        
        setSelectedDealer(e.target.value);
    }

    const handleSalesPersonChange = (e) =>{        
        const newVal = e.target.value;        
        setSelectedSalesPerson( (old) => { return {...old,...newVal} });
    }

    const handleCreateReferral = async (e) =>{
        //e.preventDefault();
        debugger;
        
        const callValidateReferral = async () =>{
              const _existingReferral = await validateReferral(parseInt(selectedDealer.dealer_Id),stockNo,auth.user.token,auth.user.loginId);
             return _existingReferral;
        }
        const callCreateReferral = async () =>{
            try{
                const referral = await createReferral(parseInt(selectedDealer.dealer_Id),parseInt(selectedSalesPerson.advisor_Id),parseInt(giftCard),purchaseOrder,membershipNo,membershipName,email,mobileNo,stockNo,friendName,parseInt(auth.user.AdvisorId),auth.user.token,auth.user.loginId);
                if (referral && referral.id > 0)
                { 
                    message.current = `Referral for customer ${referral.cust_Name} has been created`;
                    clearFields();
                    setTouched({});
                    setShowMsg(true);
                   
                }
            }
            catch(e){
                setError(e);
                message.current = "Failed to Create the record";
                setShowMsg(true);
                
            }
        }

        const existingReferral = await callValidateReferral();
        if (existingReferral.id === -1)  
            callCreateReferral();
        else
            {
                debugger;
                message.current = `Stock Number already exists against Membership No ${existingReferral.membership_Num}`;
                setShowMsg(true);
            }
    }
    // if (error)
    //     throw error;
    
        const getErrors = () =>{
           
            const result = {};
            if (!giftCard)
                result.giftCard = "Please enter Gift Card value" ;
            if (purchaseOrder === "")
                result.purchaseOrder = "Please enter Purchase Order No." ;
            if (!selectedDealer)
                result.dealer = "Please select Dealership" ;
            if (selectedDealer && selectedDealer.dealer_Id <= 0)
                result.dealer = "Please select Dealership" ;
            if (!selectedSalesPerson)
                result.salesperson = "Please select Salesperson" ;
            if (selectedSalesPerson && selectedSalesPerson.advisor_Id <= 0)
                result.salesperson = "Please select Salesperson" ;
            if (membershipNo === "")
                result.membershipNo = "Please enter Membership No." ;
            if (membershipName === "")
                result.membershipName = "Please enter Membership Name" ;
            if (email === "")
                result.email = "Please enter Email" ;
            if (email !== ""){
                const emailRegex = new RegExp(/\S+@\S+\.\S+/);
                if (!emailRegex.test(email))
                result.email = "Please enter a valid email.";                
            }
            if (mobileNo === "")
                result.mobileNo = "Please enter Mobile No" ;
            if (mobileNo !== ""){
                const mobileRegex = new RegExp(/(04)\d{8}/);
                if (!mobileRegex.test(mobileNo))
                result.mobileNo = "Please enter valid Mobile No" ;
            }
            if (friendName === "")
                result.friendName = "Please enter Friend Name" ;
            if (stockNo === "")
                result.stockNo = "Please enter Stock No." ;
            return result;
        }

        const UIErrors = getErrors();

        const handleBlur = (e) =>{
            // debugger;
            // console.log(e.target.id !== undefined ? e.target.id : e.syntheticEvent.target.id);
            setTouched( oldValue => { return {...oldValue,[e.target.id !== undefined ? e.target.id : e.syntheticEvent.target.id ]:true } }) ;
        }
    return(
        <div className="referralsContainer">
            <div style={{width:"50%",marginTop:"10px",marginBottom:"20px",fontWeight:"bolder"}}>
                {parseInt(id) === 0 ? "ADD REFERRAL" : "VIEW REFERRAL"}
            </div>
            <div style={{width:"50%",marginBottom:"10px"}}>

            <NumericTextBox id="txtGiftCard" label="Gift Card Value" value={ giftCard} onChange={ (e) => { setGiftCard( e.target.value)}} style={{width:"60%"}} max="1000" min="1" onBlur={(e) => handleBlur(e)}></NumericTextBox>
            {touched.txtGiftCard && <p role="alert">
                            {UIErrors.giftCard}
                        </p>}
            </div>
<div style={{width:"50%"}}>

            <Input id="txtPurchaseOrder" onBlur={(e) => handleBlur(e)} label="Purchase Order No." value={purchaseOrder} onChange={ (e) => { setPurchaseOrder( e.target.value)}} maxLength={25} style={{width:"60%"}}></Input>
            {touched.txtPurchaseOrder && <p role="alert">
                            {UIErrors.purchaseOrder}
                        </p>}
</div>
            <div style={{marginTop:"20px",marginBottom:"10px",width:"50%",textDecorationLine:"underline"}}>
                DEALERSHIP INFORMATION
                {/* <hr ></hr> */}
            </div>
            
            <div style={{width:"50%"}}>      
               
            {!loading &&
            <DropDownList label="Dealership"
            // defaultItem={{dealer_Id:-1,dealer_Name:'Select'}}
                data={dealers}
                textField="dealer_Name"
                dataItemKey="dealer_Id"
                value={selectedDealer}
                onChange={handleDealerChange}
                style={{width:"60%",marginBottom:"15px"}}
            ></DropDownList>
            }                      
            

                {selectedDealer && salesPersons && < DropDownList label="Salesperson"
                           
                data={salesPersons}
                textField="name"
                dataItemKey="advisor_Id"
                value={selectedSalesPerson}
                onChange={handleSalesPersonChange}
                style={{width:"60%",marginBottom:"10px"}}
                ></DropDownList>
        }
            </div>   
            <div style={{marginTop:"20px",marginBottom:"10px",width:"50%",textDecorationLine:"underline"}}>
                CUSTOMER INFORMATION
                {/* <hr></hr> */}
            </div>
            <div style={{width:"50%"}}>
                <Input  id="txtMembershipNo" onBlur={(e) => handleBlur(e)} label="Membership No." value={membershipNo} onChange={ (e) => { setMembershipNo( e.target.value)}}  maxLength={25}  style={{width:"60%",marginBottom:"10px"}}></Input>
                {touched.txtMembershipNo && <p role="alert">
                            {UIErrors.membershipNo}
                        </p>}
            </div>
            <div style={{width:"50%"}}>
                <Input  id="txtMembershipName" onBlur={(e) => handleBlur(e)} label="Member Full Name" value={membershipName} onChange={ (e) => { setMembershipName( e.target.value)}}  maxLength={50} style={{width:"60%",marginBottom:"10px"}}></Input>
                {touched.txtMembershipName && <p role="alert">
                            {UIErrors.membershipName}
                        </p>}
            </div>
             <div style={{width:"50%"}}>
            <Input  id="txtMobileNo" onBlur={(e) => handleBlur(e)} label="Mobile" value={mobileNo}  onChange={ (e) => {setMobileNo( e.target.value)}}  maxLength={10}  style={{width:"60%",marginBottom:"10px"}}></Input>
            {touched.txtMobileNo && <p role="alert">
                            {UIErrors.mobileNo}
                        </p>}
            </div>
            <div style={{width:"50%"}}>
            <Input  id="txtEmail" onBlur={(e) => handleBlur(e)} label="Email" value={email} onChange={ (e) => { setEmail( e.target.value)}}  maxLength={50}  style={{width:"60%",marginBottom:"10px"}}></Input>
            {touched.txtEmail && <p role="alert">
                            {UIErrors.email}
                        </p>}
            </div>
           
            <div style={{width:"50%"}}>
            <Input  id="txtFriendName" onBlur={(e) => handleBlur(e)} label="Name of Friend or Relative Referred" value={friendName} onChange={ (e) => { setFriendName( e.target.value)}}  maxLength={50}  style={{width:"60%",marginBottom:"10px"}}></Input>
            {touched.txtFriendName && <p role="alert">
                            {UIErrors.friendName}
                        </p>}
            </div>
            <div style={{width:"50%"}}>

            <Input  id="txtStockNo" onBlur={(e) => handleBlur(e)} label="Stock Number of Vehicle Purchased" value={stockNo} onChange={ (e) => { setStockNo( e.target.value)}}  maxLength={25}  style={{width:"60%",marginBottom:"10px"}}></Input>
            {touched.txtStockNo && <p role="alert">
                            {UIErrors.stockNo}
                        </p>}
            </div>
            <br/><br/>
            {showMsg && 
                <div style={{width:"50%"}}>
                    {message.current}
                </div>
            }
            <div style={{width:"50%"}}>
<br/>
            {parseInt(id) === 0 && <Button primary={true} disabled={(Object.keys(UIErrors).length === 0 ) ? false : true} onClick={(e) => handleCreateReferral(e)} >Create</Button>}
            </div>
        </div>
    );
}

export default Referrals;