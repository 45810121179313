
const Home = () =>{
    
    return (
        <div>
            <div className="home-container">                
                
                <img src="/images/HomeLogo.jpg" alt="Referral Advantage" className="logo"></img>
             
             {/* <div style={{height:"100px"}}>

             </div> */}
             {/* <div style={{backgroundColor:"white",color:"black",lineHeight:"30px",width:"432px",textAlign:"center", fontSize:"24",fontWeight:"bolder"}}> 
                    Welcome to the Referral Advantage Portal!
             </div> */}
            </div>
            {/* <style>
                {
                    `.container{
                        // height:100vh;
                        width:100%;
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        align-items:center;
                        // background-color:pink;
                    }
                    .logo{
                        height:140px;
                        align-self: center;
                        //background-color:white;
                    }
                    .heading{
                        height:30px;
                        align-self: center;
                        //background-color:blue;
                    }
                    .form{
                        height:180px;
                        align-self: center;
                        //background-color:green;
                    }
                    `
                }
            </style> */}
        </div>
    );
}

export default Home;