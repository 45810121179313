const baseUrl = process.env.REACT_APP_BASE_API_URL;

// export const  getReferralsWithDataState = async (Advisor_Id,token,dataState) => {
//     debugger;
    
//     const response = await fetch(baseUrl + `api/dealership/${Advisor_Id}`
//         ,{
//             method: 'POST', // *GET, POST, PUT, DELETE, etc.
//             mode: 'cors',
            
//             headers:{
//                 'Content-Type': 'application/json', 
//                 'Accept' : 'application/json',
//                 'Authorization' : `Bearer ${token}`
//             }
            
//              ,body: JSON.stringify(dataState)
             
//         }
//     );
//     if (response.ok) {
//         const data = await response.json();                
//         return data;
//     }
//     throw response;
// }

export const  getReferrals = async (Advisor_Id,token,loginId) => {
    //debugger;
    
    const response = await fetch(baseUrl + `api/referral/${Advisor_Id}`
        ,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            
            headers:{
                'Content-Type': 'application/json', 
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${token}`,
                'loginId' : `${loginId}`
            }            
            // ,body: JSON.stringify(dataState)
             
        }
    );
    if (response.ok) {
        const data = await response.json();                
        return data;
    }
    throw response;
}

export const  getReferralsById = async (Id,token,loginId) => {
    //debugger;
    
    const response = await fetch(baseUrl + `api/referral/getReferralsById/${Id}`
        ,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            
            headers:{
                'Content-Type': 'application/json', 
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${token}`,
                'loginId' : `${loginId}`
            }            
            // ,body: JSON.stringify(dataState)
             
        }
    );
    if (response.ok) {
        const data = await response.json();                
        return data;
    }
    throw response;
}

export const validateReferral =  async (Dealer_Id,Stock_No,token,loginId) =>{
    try{
        const response = await fetch(baseUrl + `api/referral/validateReferral/${Dealer_Id}/${Stock_No}`
        ,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            
            headers:{
                'Content-Type': 'application/json', 
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${token}`,
                'loginId' : `${loginId}`
            }            
            // ,body: JSON.stringify(dataState)
             
        }
    );
        if (response.ok) {
            const data = await response.json();                
            return data;
        }
        throw response;
    }
    catch(e){
        throw e;
    }
}
export const createReferral = async (Dealer_Id,Salesperson_Id,Giftcard_Value,Purchase_Order_Num,Membership_Num,Cust_Name,Cust_Email,Cust_Mobile_No,Stock_No,Referral_Name
    ,Created_By_Advisor_Id,token,loginId) => {
    try{

        const referralRecord = {
            Dealer_Id,Salesperson_Id,Giftcard_Value,Purchase_Order_Num,Membership_Num,Cust_Name,Cust_Email,Cust_Mobile_No,Stock_No,Referral_Name
            ,Created_By_Advisor_Id
        }
        const response = await fetch(baseUrl + `api/referral`
        ,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            
            headers:{
                'Content-Type': 'application/json', 
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${token}`,
                'loginId' : `${loginId}`
            }
            //,data:a
            ,body: JSON.stringify(referralRecord)
            
        }
        );
        if (response.ok) {
            const data = await response.json();                
            return data;
        }        
        throw response;
    }
    catch(e)
    {
        throw e;
    }
}

export const approveReferrals = async (Advisor_Id,token,list,loginId) => {
    //debugger;
    const response = await fetch(baseUrl + `api/referral/${Advisor_Id}/${list}`
        ,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            
            headers:{
                'Content-Type': 'application/json', 
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${token}`,
                'loginId' : `${loginId}`
            }
            //,data:a
             //,body: JSON.stringify(referralRecord)
             
        }
    );
    if (!response.ok) {
        //const data = await response.json();                
        //return data;
        throw response;
    }
}