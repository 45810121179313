import {useState,useRef} from "react";
import {useLocation,useNavigate,useParams} from "react-router-dom";
import {AppBar,AppBarSection,AppBarSpacer,Drawer, DrawerContent} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
//import { Popup } from '@progress/kendo-react-popup';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {useAuth} from "./customHooks/authContext";
import  "./styles/appLayout.css";

const items = [
    { text: 'Home',value:'/home', icon: 'k-i-layout',  route: '/home' },
    // { separator: true },
    { text: 'Create New Referral',value:'/referrals', icon: 'k-i-plus-circle', route: '/referrals/0' },    
    { text: 'View Existing Referrals',value:'/referralsList', icon: 'k-i-grid', route: '/referralsList' },    
    // { text: 'Sales People',value:'/salesperson', icon: 'k-i-tell-a-friend', route: '/salesperson' },    
];

const AppLayout = ({children}) =>{
    const auth =  useAuth();
    const [expanded, setExpanded] = useState(true);
    //const [showPopup, setShowPopup] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const anchor = useRef(null);
    const location = useLocation();
    const navigate =  useNavigate();
    const {id} =  useParams();
    const toggleMenuItems = () =>{
        setExpanded( oldValue => !oldValue);
    }
    const selectMenuItem = (e) => {      
        //console.log(e.itemTarget)  ;
        navigate(`${e.itemTarget.props.route}` );
    }
    
    // const togglePopup = () =>{
    //     setShowPopup(!showPopup);
    // }
        
    let selected = location.pathname;
    let menuItems = [];
    const createMenuItems = () => {
        items.map( item => {
            if (id>0){
                selected = location.pathname.slice(0,location.pathname.indexOf(id));
            }
            if (item.value === "/home")
                menuItems.push({...item,selected:item.value === selected});            
            if (item.value === "/referrals" && auth.user.flag_can_create_referral === "True") {                                                    
                menuItems.push({...item,selected:item.value === selected});
                //return  ({...item,selected:item.value === selected})
            }
            if (item.value === "/referralsList" && auth.user.flag_can_view_referral_history === "True") {                                                    
                menuItems.push({...item,selected:item.value === selected});                
            }
            if (item.value === "/salesperson" && auth.user.flag_can_create_salesperson === "True") {                                                    
                menuItems.push({...item,selected:item.value === selected});
                
            }
            }
        )
        //console.log(items);
    }
    
    createMenuItems();
    return (
        
    <>
    {/* {console.log(location,id,location.pathname.indexOf(id))} */}
        <AppBar  positionMode="sticky">
            <AppBarSection>
            
                <Button icon="menu" look="flat" onClick={toggleMenuItems}></Button>
               
            </AppBarSection>
            {/* <AppBarSpacer style={{ width: "0" }} /> */}
            <AppBarSection>
                <img src="images/AppLogo.png" alt="Referral Advantage" style={{width:"100px"}}></img>
                
            </AppBarSection>
            <AppBarSpacer />
            <AppBarSection>
            {auth.user && <> <span style={{marginRight:'20px'}}> {`${auth.user.firstName} ${auth.user.lastName}`}</span></> }
          <a ref={anchor} onClick={()=>setShowDialog(!showDialog)} >
              {/* onClick={togglePopup} */}
    {/* 
            <Avatar shape="circle" type="image" ><img src={kendokaAvatar}  />                        
                        </Avatar> */}
                        <span  className="k-icon k-i-logout " style={{cursor:"pointer"}}></span>
          </a>
    
                          {/* <Popup
                            anchor={anchor.current}
                            show={showPopup}                            
                            
                            style={{ marginLeft: -10,marginTop:10 }}
                        >
                            <div className="popupContent">
                                
                                <ul className="popupList">
                                    <li className="popupListItem"><span onClick={ () => {togglePopup(); setShowDialog(!showDialog)} } > Sign Out</span></li>
                                    <li className="popupListItem"><span>About</span></li>
                                    
                                </ul>
                            </div>
                        </Popup> */}
    
                        
            </AppBarSection>
            
        </AppBar>
        <Drawer className="drawer" positionMode="" 
            expanded={expanded}
             position={'start'}
                         mode={'push'}
                     mini={true}
            
            items = {menuItems}     
            onSelect={selectMenuItem}            
        >
            <DrawerContent>
            
                {children}
            </DrawerContent>
            
        </Drawer>
    
        {showDialog && 
              <Dialog title={"Signout"} 
               onClose={()=>setShowDialog(false)} 
              >
                  <p>Do you wish to Sign out?&nbsp;&nbsp;&nbsp;</p>
                  
                   <DialogActionsBar>
                       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       
          <Button primary={true} onClick={() => { auth.signOut();}}>Yes</Button>
         &nbsp;&nbsp;&nbsp;&nbsp;
          <Button onClick={()=>setShowDialog(false)} >No</Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          
          
        </DialogActionsBar> 
        <DialogActionsBar>&nbsp;</DialogActionsBar>
              </Dialog>
            }        
        </>
        );
    }
    
    export default AppLayout;