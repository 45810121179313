import React from 'react';
import ReactDOM from 'react-dom';
import "./kendo-theme-custom.scss";
//import '@progress/kendo-theme-bootstrap/dist/all.css';
import App from './App';
import ErrorBoundary from "./errorboundary";
import {BrowserRouter} from "react-router-dom";
import AuthProvider from "./customHooks/authContext";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>      
        <AuthProvider>        
          <App />
        </AuthProvider>      
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);


