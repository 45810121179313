import {useState,useEffect,useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "./customHooks/authContext";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {Button} from "@progress/kendo-react-buttons";
import {ColumnMenu} from "./columnMenu";
import { getReferrals,approveReferrals } from "./services/referralsService";
import { process } from '@progress/kendo-data-query';
import {loadingPanel} from "./styles/loadingPanel";
import "./styles/referralList.css"
const ReferralsList = () =>{
    const auth = useAuth();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const [referrals,setReferrals] = useState(null);
    const totalRecords = useRef(0);
    const [pageData,setPageData] = useState([{}]);
    const [dataState,setDataState] = useState( ()=> {
        if (auth.user.flag_can_approve_referral === "True"){ return {
        skip:0,take:10
    //     ,filter:
    //     {logic:"and"
    //     ,filters:[
    //         {field:"dealer_Id"
    //         ,operator:"eq"
    //         ,value:"1"      
    //         }   
    // ]}
        
        ,sort: [
            { field: 'approved_By_Advisor_Id', dir: 'asc' }
            ,{ field: 'created_Date', dir: 'desc' }
        ]
        // ,group: [
        //     // { field: 'customerID' }
        // ]
        }
    }
    else return {
        skip:0,take:10   
        ,sort: [
            { field: 'created_Date', dir: 'desc' }
        ]        
        }
        });
        const getReferralsData = async () =>{
            debugger;
            let ref = await getReferrals(auth.user.AdvisorId,auth.user.token,auth.user.loginId);            
            ref = ref.map(dataItem => {
                if (!(dataItem.approved_By_Advisor_Id && dataItem.approved_By_Advisor_Id > 0))
                    return Object.assign({ selected: false,approved: 'No' }, dataItem)
                
                    if (dataItem.approved_By_Advisor_Id && dataItem.approved_By_Advisor_Id > 0)
                        return Object.assign({ approved: 'Yes' }, dataItem);
                    else
                        return Object.assign({ approved: 'No' }, dataItem);
                
            }
                );
            setReferrals((old) => { 
                              
                setPageData( oldPage => { const newPage = process(ref, dataState); return {...oldPage,...newPage} } ); 
                totalRecords.current = ref.length;
                //setPageData(process(ref, dataState));
                //const p = [...ref];
                //setPageData(p.slice(dataState.skip,dataState.skip + dataState.take));        
                return ref;
                //return ({...old,...ref});
            }
                );
            
            setLoading(false);
            
            }
    useEffect( () => {
        

        getReferralsData();
    },[])

    useEffect( () => {       

        //console.log("Referrals Data Changed");
    },[referrals])
    const dataStateChange = (e) =>{
       // debugger;
        setLoading(true);
        
            setPageData((oldPage) => { 
            const newPage = process(referrals, e.dataState); 
            totalRecords.current = newPage.length;
            //console.log(d);
            return {...oldPage,...newPage};});
            
        setDataState(e.dataState);
        setLoading(false);
        
        
    }

    const selectionChange = (e) =>{
        //console.log(pageData);
        const updatedData = pageData.data.map( item => {
            if (item.dealer_Id === e.dataItem.dealer_Id){
                item.selected = !e.dataItem.selected;
            }
            return item;
        })
        setPageData((old) => {return {...old,data:updatedData,total:pageData.total}} );
    }

    const headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const updatedData = pageData.data.map(item=>{
            if (item.hasOwnProperty("selected")){
                item.selected = checked;
                return item;
            }
            return item;
        });
        setPageData((old) => {return {...old,data:updatedData,total:pageData.total}} );
    }

    const handleCheck = (e,id) =>{
        
         const checked = e.target.checked;
        const updatedData = pageData.data.map(item=>{
            if (item.id === id)
                item.selected = checked;
            return item;
        });
        setPageData((old) => {return {...old,data:updatedData,total:pageData.total}} );
    }
    const changeSelectionOption = (props) =>{
        if (props.dataItem.approved_By_Advisor_Id && props.dataItem.approved_By_Advisor_Id > 0)
        {
            return (<td></td>);
        }
        else
        {
            return( <td>
                <input type="checkbox" defaultChecked={props.dataItem.selected} onChange={ (e) => handleCheck(e,props.dataItem.id)}></input>
            </td> );
            
        }
    }
    const showApprovalStatus = (props) =>{
        if (props.dataItem.approved_By_Advisor_Id && props.dataItem.approved_By_Advisor_Id > 0)
        {
            return (<td
                colSpan={props.colSpan}
                role={'gridcell'}
                aria-colindex={11}
                aria-selected={false}
                // {...{
                //   [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                // }}
                // {...navigationAttributes}
            >Yes</td>);
        }
        else
        {
            return( <td 
                colSpan={props.colSpan}
                role={'gridcell'}
                aria-colindex={11}
                aria-selected={false}
                // {...{
                //   [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                // }}
                // {...navigationAttributes}
                >
                No
            </td> );
            
        }
    }
const rowClick = (e) =>{
    //debugger;
    navigate(`/referrals/${e.dataItem.id}`);
}
    const handleApproveReferrals = (e) =>{
        const list =[];
        referrals.map(r => {if (r.selected) list.push(r.id);} );
        const callApproveReferrals = async()=>{
             await approveReferrals(auth.user.AdvisorId,auth.user.token,list,auth.user.loginId);
        }
        
        callApproveReferrals();
        //debugger;
        setLoading(true);
        setTimeout(()=>{
            getReferralsData();
        },1000)
    }
    // const filterChange = (e) => {
    //     debugger;
    //     if (e.syntheticEvent.type === "click"){
    //         const ds = {
    //             filter: e.filter
    //             //,group: undefined
    //             ,skip: 0
    //             ,sort: dataState.sort
    //             ,take: dataState.take
    //         }
    //         setPageData((oldPage) => { const newPage = process(referrals, ds); 
    //             //console.log(d);
    //             return {...oldPage,...newPage};});
                
    //         setDataState(ds);
    //     }
        
    // }

    // const pageChange = (e) => {
    //     const p = [...referrals];
    //     setPageData(p.slice(e.page.skip,e.page.skip + e.page.take));
    //     setDataState({skip:e.page.skip,take:e.page.take})
    // }

    
    return(
        <div className="referralListContainer">
        
        {/* {loading && <Loader style={{position:"absolute", top: "50%",left:"50%"}} size='large' type="pulsing" />} */}
        {loading && loadingPanel}
        {/* {!loading && <h1>{console.log(referrals.length)}</h1>} */}
        <Grid
            data={pageData}{...dataState}
            scrollable="none"
            skip={dataState.skip}
            take={dataState.take}
            pageSize={10}
            total={totalRecords.current}
            //sortable={true}
            filterable={true}
            //groupable={true}
            reorderable={true}
            //pageable={{ buttonCount: 4,  }}
            pageable={{pageSizes: true}}
            //onPageChange={pageChange}
            onDataStateChange={dataStateChange}
            selectedField="selected"
            onSelectionChange={selectionChange}
            onHeaderSelectionChange={headerSelectionChange}            
            onRowDoubleClick={rowClick}
            //onFilterChange={filterChange}   
                     
        >
            {auth.user.flag_can_approve_referral === "True" && <GridColumn field="selected" title="Select" filterable={false} cell={changeSelectionOption}></GridColumn>}
            {/* filterable={true} filter={'boolean'} */}
            {/* <GridColumn field="dealer_Id" filter={'numeric'} /> */}
            <GridColumn field="dealer_Name" title="Dealer Name" filterable={true}  />
            {/* columnMenu={ColumnMenu }*/}
            <GridColumn field="salesperson_Name" title="Sales Person" filterable={true} />
            <GridColumn field="giftcard_Value"  title="Gift Card" filterable={false} />
            <GridColumn field="purchase_Order_Num" title="Purchase Order" filterable={true} />
            <GridColumn field="membership_Num" title="Membership No" filterable={true} />
            <GridColumn field="cust_Name" title="Membership Name" filterable={true} />
            <GridColumn field="cust_Email" title="Email" filterable={true} />
            <GridColumn field="cust_Mobile_No" title="Mobile" filterable={true} />
            <GridColumn field="stock_No" title="Stock No" filterable={true} />
            <GridColumn field="approved" title="Approved" filterable={true}  />
            {/* <GridColumn field="approved_By_Advisor_Name" title="Approved By" filterable={true} /> */}
        </Grid>
        
        <br/>
        {auth.user.flag_can_approve_referral === "True" && <Button primary={true}  onClick={(e) => handleApproveReferrals(e)} style={{marginLeft:"10px"}}>Approve Referral</Button>}
        </div>
    );
}

export default ReferralsList;