const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const  getDealerSalesPersons = async (Dealer_Id,loginId) => {
    //debugger;
    const response = await fetch(baseUrl + `api/salesperson/${Dealer_Id}`
         ,{
        //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
        //     mode: 'cors',
            
             headers:{
                 'Content-Type': 'application/x-www-form-urlencoded', 
                 'Accept' : 'application/json',
                 //'Authorization' : `Bearer ${token}`,
                'loginId' : `${loginId}`

             }
         }
    );
    if (response.ok) {
        const data = await response.json();                
        return data;
    }
    throw response;
}