
import './styles/App.css';
import {Routes,Route} from "react-router-dom";
import {useAuth} from "./customHooks/authContext";
import AppLayout from "./appLayout";
import Login from "./login";
import Home from "./home";
import Referrals from "./referrals";
import ReferralsList from "./referralsList";
// import Salesperson from "./salesperson";

function App() {
  const auth = useAuth();

  if (!auth.user) 
  return (  <Login></Login>);
  return (
    <>
      <AppLayout>  
      {/* <div id="componentContainer">     */}
        <Routes>
          <Route path="/home"   element={ <Home ></Home>}></Route>
          <Route path="/referrals/:id"   element={ <Referrals ></Referrals>}></Route>
          <Route path="/referralsList"   element={ <ReferralsList ></ReferralsList>}></Route>
          {/* <Route path="*" component={<Login></Login>} /> */}
          {/* <Route path="/salesperson"   element={ <Salesperson ></Salesperson>}></Route> */}
        </Routes>
        {/* </div> */}
      </AppLayout>
    </>
  );
}

export default App;
