import {useState} from "react";
import {useAuth} from "./customHooks/authContext";
import {Input} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import { Ripple } from "@progress/kendo-react-ripple";
// import {Loader} from "@progress/kendo-react-indicators";
import {loadingPanel} from "./styles/loadingPanel";
const Login = () =>{
    const [uName,setUName] =  useState("");
    const [pwd,setPwd] = useState("");
    const [touched,setTouched] = useState({});

    const auth = useAuth();

    const getErrors = () =>{
        const result = {};
        if (uName === "")
            result.userName = "Please enter username" ;
        if (pwd === "")
            result.password = "Please enter password" ;
        if (auth && auth.loginError){
            
            console.log(auth.loginError);
            result.loginError = auth.loginError;
        }
        return result;
    }

    const submitForm = (e)=>{
        e.preventDefault();
                
         const verifyUser = async () =>{                    
             //debugger;
             try
             {
                 await auth.signIn(uName,pwd,"/home");
             }
             catch(e){

             }
             
             
         }
        
          verifyUser();
        
    }

    const handleBlur = (e) =>{
        setTouched( oldValue => { return {...oldValue,[e.target.id]:true } }) ;
    }

    const errors = getErrors();

    return(
        <Ripple>
         <div className="login-wrapper" style={{backgroundImage:`url("/images/LoginLogo.jpg")`}}> 
             
                {/* <img src="/images/LoginLogo.jpg" alt="Roadside Advantage" className="logo"></img> */}
             
             <div  className="heading" >
                DEALERSHIP LOGIN
                <hr style={{borderColor:"black"}}></hr>
             </div>
             <div  className="form">
                <form onSubmit={(e) => submitForm(e)}>    
                <div className="controlDiv" >                        
                        <Input id="userName" name="userName" placeholder="Username" style={{width:"100%"}} 
                        value={uName} onChange={ (e) => { setUName( e.target.value); }}
                        onBlur={(e) => handleBlur(e)}></Input>
                        {touched.userName  &&(
                        <p role="alert">
                            {errors.userName}
                        </p>
                        )}
                    </div>
                    <div className="controlDiv" >                 
                        <Input id="password" type="password" placeholder="Password"  style={{width:"100%"}} 
                        value={pwd} onChange={ (e) => { setPwd( e.target.value); }}
                        onBlur={(e) => handleBlur(e)}
                        ></Input>
                        {touched.password  &&(
                        <p role="alert">
                            {errors.password}
                        </p>
                        )
                        }
                    </div>
                    <div style={{paddingTop:10}}>
    {auth.loginError !== null && !auth.loading && 
                    <>
                   
                     <p role="alert">
                            {errors.loginError}
                         </p> <br/><br/>
                         </>
                        }
                    {!auth.loading && <div className="controlDiv" ><Button primary={true} icon="login"  disabled={errors.userName   || errors.password} >Login</Button></div>}
                    
                    {auth.loginError !== null && !auth.loading && console.log(errors.loginError)}
                    {auth.loading &&  loadingPanel                  
                            // <Loader size='large' type="pulsing" />
                        
                        }
                    </div>
                </form>
            </div>
            
        </div>
        </Ripple>
    );
}

export default Login;